@import "../styles/Variables.scss";

.stat-block {
	width: auto;
	display: flex;
	align-items: center;
}

.sb-number {
	font-weight: $fw-semi-bold;
	color: $colour-secondary;
	font-size: 39px;
	margin-right: 12px;
}

.sb-text {
	font-weight: $fw-regular;
	font-size: 15px;
}