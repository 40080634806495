@import "../styles/Variables.scss";

.input-field-wrapper {
	label {
		color: $colour-secondary;
		font-weight: $fw-regular;
		font-size: 16px;
		opacity: 0.5;

		&.MuiFormLabel-filled {
			opacity: 0.5;
			font-size: 12px;
			transform: translate(17px, 11px) scale(0.7);
		}
		
		&.Mui-focused {
			color: $colour-secondary;
			opacity: 1;
			font-size: 12px;
			transform: translate(17px, 11px) scale(0.7);
		}
		
		&.Mui-error {
			&.Mui-focused {
				color: $colour-error;
			}
		}
	}

	.MuiFilledInput-root {
		border-radius: 10px;
		background-color: $colour-primary-light-blue;
		border: 1.5px solid $colour-primary-light-blue;
		padding: 0;
		color: $colour-secondary;
		transition: 200ms ease;

		&:hover {
			background-color: $colour-primary-light-blue;
			border-color: rgba($colour-secondary, 0.5);
		}

		.MuiFilledInput-input {
			padding-top: 21px;
			padding-bottom: 8.5px;
		}
		
		&.Mui-focused {
			background-color: $colour-primary-light-blue;
			border-color: $colour-secondary;

			.MuiFilledInput-input {
				padding-top: 21px;
				padding-bottom: 8.5px;
			}
		}
		
		&:after, &:before {
			display: none;
		}
	}
	
	
	.MuiFilledInput-input {
		padding: 12.5px 15px;
		padding-top: 17px;
		padding-bottom: 12.5px;
	}

	.Mui-error {
		&.MuiFormLabel-root {
			color: $colour-error;
		}

		&.MuiFilledInput-root {
			background-color: rgba($colour-error, 0.1);
			border-color: rgba($colour-error, 0.7);
		}

		&.MuiFormHelperText-root {
			color: $colour-error;
			font-family: $font-primary;
		}

		.MuiFilledInput-input {
			color: $colour-error;
		}
	}
}