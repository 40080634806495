@import "../styles/Variables.scss";

.page-content-header-wrapper {
	width: 100%;
	height: 130px;
	display: flex;
	padding: 55px 100px 25px 48px;
}

.page-content-header-inner {
	width: 100%;
	max-width: $max-extra-large-size;
}

.page-title {
	margin: 0;
}

.header-icon-wrapper {
	display: flex;
	align-items: center;
	
	svg {
		width: 20px;
		margin-right: 11px;
	}
}