@import "../styles/Variables.scss";

.numbered-list-item {
	border-radius: $border-radius;
	background-color: $colour-primary-light-blue;
	font-size: 16px;
	padding: 8px 16px;
	display: flex;
	margin-bottom: 8px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.nli-number {
	font-weight: $fw-semi-bold;
	color: $colour-secondary;
	margin-right: 16px;
}

.nli-text {
	font-weight: $fw-regular;
}