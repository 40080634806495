$color: rgb(37, 74, 143);
$accent: rgb(219, 181, 100);
.empanel-outer {
	font-family: Poppins, sans-serif;
	margin: 5px;
	>header {
		font-size: 1.8rem;
	}

	.section {
		header {
			display: flex;
			flex-direction: row;
			color: $color;
			margin: 10px 0;
			.number {
				font-weight: 600;
				border: solid 2px $color;
				border-radius: 50%;
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 4px;
			}
		}

		>div {
			display: flex;
			align-items: center;

			>div {
				margin-right: 5px;
			}

			.province-select {
				width: 250px;
			}
			.size-select {
				width: 150px;
			}

			&.wrapper {
				width: 900px;
				height: 250px;
				overflow: auto;
				border: solid 1px #ccc;


				.judges {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					width: 100%;
					margin-top: 363px;
					.judge {
						display: flex;
						flex-direction: row;
						width: 100%;
						justify-content: stretch;
						border-bottom: solid 1px #ccc;
						position: relative;
						z-index: 0;
						&:first {
							border-top: solid 1px #ccc;
						}
						.details {
							display: flex;
							flex-direction: column;
							flex-grow: 1;
							height: 50px;
						}

						.notes {
							height: 25px;
						}
						&.recused {
							opacity: 0.4;

							.notes {
								color: red;
							}
						}

						.update {
							width: 50px;
							background-color: #eee;
							border-left: solid 1px #ccc;
							color: $color;

							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
						}
						.menu {
							position: absolute;
							right: 50px;
							top: 0px;
							z-index: 300;
							background-color: #eee;
							color: $color;
							border: solid 1px #ccc;
							font-size: 0.9rem;
							padding: 0 5px;

							.reason {
								&:hover {
									background-color: $color;
									color: $accent;
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
}

.panel {
	width: 900px;
	display: flex;
	flex-wrap: wrap;
	.judge {
		border: solid 1px #eee;
		border-radius: 10px;
		padding: 0 8px;
		margin-bottom: 4px;
	}
}
//Judge formatting
.personal {
	display: flex;
	flex-direction: row;

	>div {
		margin-right: 3px;
	}
	.title, .familyName {
		font-weight: 500;
		color: $color;
	}
	.givenName {
		font-weight: 400;
		color: #777;
	}
	.seniority {
		color: white;
		background-color: $accent;
		border-radius: 10px;
		height: 15px;
		width: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.75rem;
		margin-top: 3px;
	}
}

.appealed-cases {
	width: 900px;
	border: solid 1px #eee;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.appealed-case {
	width: 100%;
	display: flex;
	flex-direction: row;
	color: $color;
	border-bottom: solid 1px #eee;
	>div {
		margin-right: 4px;
	}
	.province, .date-from, .date-till {
		color: $accent;
	}
}