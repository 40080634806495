@import "../../styles/Variables.scss";

.link-section-edit {

	&.header {
		padding-top: 35px;
	}

	div.portal-tooltip {
		padding-left: 10px;
		padding-right: 10px;
		cursor: pointer;
	}

}
