@import "../styles/Variables.scss";

.dashboard-container {
	height: auto;
	display: grid !important;
	grid-gap: 16px;
	grid-template-columns: auto;
	grid-template-rows: repeat(4, minmax(300px, 500px));
	
	@media screen and (min-width: $breakpoint-xl){
		height: 100%;
		grid-template-columns: 6fr 5fr;
		grid-template-rows: 100% ;	//55 45
	}
}

.top-judges-card {
	height: calc(25% - 16px);//calc(60% - 16px);
	margin-bottom: 16px;
}

.stats-card {
	height: calc(40%);
}

.links-card {
	height: calc(55% - 16px);
	margin-bottom: 16px;
}

.news-card {
	height: calc(74%);
}

.calendar-card {
	height: calc(45%);
}