
@mixin text-ellipsis() {
	text-overflow: ellipsis;
	white-space: nowrap;
	*white-space: nowrap;
	overflow: hidden;
  }
  
  @mixin unselectable() {
	moz-user-select: -moz-none;
	-moz-user-select: none;
	-o-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  
  .month-picker {
	position: relative;
  
  
	>.rmp-container {
	  position: relative;
	  box-sizing: border-box;
	  -webkit-tap-highlight-color: rgba(0,0,0,0);
	  z-index: 100;
	  top: 1px;
	  left: -10000px;
	  //width: 100%;
	  //height: 100%;
	  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  
	  @media screen and (max-width: 767px) {
		position: fixed;
		top: 0;
		left: -10000px;
		width: 100%;
		height: 100%;
		transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
	  }
  
	  &.rmp-table {
		display: table;
	  }
  
	  &.show {
		left: 0;
		transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  
		.rmp-overlay {
		  left: 0;
		}
	  }
  
	  .rmp-overlay {
		position: fixed;
		height: 100%;
		width: 100%;
		z-index: 9;
		top: 0;
		left: -10000px;
		opacity: 1;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		will-change: opacity;
		//background-color: rgba(0, 0, 0, 0.22);
		transform: translateZ(0);
		transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  
		@media screen and (max-width: 767px) {
		  background-color: rgba(0, 0, 0, 0.25);
		}
	  }
  
	  .rmp-cell {
		display: table-cell;
		vertical-align: middle;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
  
		@media screen and (max-width: 767px) {
		  //position: relative;
		  vertical-align: bottom;
		}
	  }
  
	  $popup-offset-y: 64px;
	  $option-height: 3.4rem;
	  $mon-pad-width: 20rem;
	  $mon-pad-cols: 3;
	  $mon-pad-rows: round(12 / $mon-pad-cols);
	  $mon-option-width: 100% / $mon-pad-cols;
	  $m-mon-pad-cols: 4;
	  $m-mon-pad-rows: round(12 / $m-mon-pad-cols);
	  $m-mon-option-width: 100% / $m-mon-pad-cols;
  
	  $popup-pad: 0.4rem;
	  $pad-head-height: 3.4rem;
  
	  $m-popup-height: $option-height * $m-mon-pad-rows + $pad-head-height + $popup-pad * 2;
	  $m-range-popup-height: ($option-height * $m-mon-pad-rows + $pad-head-height) * 2 + $popup-pad * 2;
  
	  .rmp-popup {
		position: absolute;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
		margin: 0 auto;
		z-index: 10;
		font-size: 1.2rem;
		opacity: 0;
		border-radius: 3px;
		padding: $popup-pad;
		box-sizing: content-box;
  
		@media screen and (max-width: 767px) {
		  box-sizing: border-box;
		  bottom: 0;
		  width: 100%;
		  max-width: 100%;
		  height: $m-popup-height;
		  transform: translate3d(0, $m-popup-height, 0);
  
		  &.range {
			height: $m-range-popup-height;
			transform: translate3d(0, $m-range-popup-height, 0);
  
			.rmp-pad {
			  margin-top: $popup-pad;
  
			  &:first-of-type {
				margin-top: 0;
			  }
			}
		  }
		}
		@media screen and (min-width: 768px) {
		  transform: translate3d(0, -($popup-offset-y), 0);
		  top: 0;
		  width: $mon-pad-width;
  
		  &.range {
			width: $mon-pad-width * 2 + 0.6rem;
			padding: 0.6rem;
		  }
		}
  
		&.show {
		  opacity: 1;
		  transform: translate3d(0, 0, 0);
		}
  
		&:after {
		  content: ' ';
		  clear: both;
		  display: table; //key for clear
		}
  
		.rmp-pad {
		  position: relative;
  
		  @media screen and (min-width: 768px) {
			box-sizing: border-box;
			float: left;
			width: $mon-pad-width;
  
			&:nth-of-type(2) {
			  float: right;
			}
		  }
  
		  // header part for year and buttons
		  >div {
			label {
			  display: block;
			  font-size: 1.4rem;
			  text-align: center;
			  line-height: $pad-head-height;
  
			  b {
				font-weight: normal;
				margin-right: 0.5em;
			  }
			}
			i {
			  font-style: normal;
			  text-align: center;
			  width: $pad-head-height;
			  height: $pad-head-height;
			  line-height: $pad-head-height;
			  position: absolute;
			  top: 0;
  
			  &.prev {
				left: 0;
			  }
			  &.next {
				right: 0;
			  }
			}
		  }
  
		  ul, li {
			list-style-type: none;
			margin: 0;
			padding: 0;
		  }
		  ul {
			display: block;
			width: 100%;
  
			&:after {
			  content: ' ';
			  clear: both;
			  display: table; //key for clear
			}
		  }
		  li {
			display: block;
			float: left;
			text-align: center;
			font-size: 1.15rem;
			border-radius: 3px;
  
			line-height: $option-height - 0.1rem;
			box-sizing: border-box;
			padding: 0.05rem;
  
			@include text-ellipsis();
			-webkit-font-smoothing: antialiased;
			-webkit-text-stroke-width: 0.2px;
			-moz-osx-font-smoothing: grayscale;
  
			transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  
			@media screen and (max-width: 767px) {
			  width: $m-mon-option-width;
			}
			@media screen and (min-width: 768px) {
			  width: $mon-option-width;
			}
  
			&.multiple {
			  background-clip: content-box;
			}
			&.range {
			  border-radius: 1px;
			}
		  }
		} //.rmp-pad
  
		// for light theme pad
		&.light {
		  color: #666;
		  background-color: rgba(255, 255, 255, 0.96);
  
		  @media screen and (max-width: 767px) {
			border-top: 1px solid #ccc;
			box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08);
  
			&.range .rmp-pad {
			  border-top: 1px solid rgba(204, 204, 204, 0.5);
			  &:first-of-type {
				border-top: 0;
			  }
			}
		  }
		  @media screen and (min-width: 768px) {
			border: 1px solid #ccc;
			box-shadow: 0 1px 5px #ddd;
  
			&.range .rmp-pad {
			  background-color: rgba(238, 238, 238, 0.9);
			}
		  }
  
		  .rmp-pad {
			.rmp-btn {
			  cursor: pointer;
			  @include unselectable();
  
			  @media screen and (min-width: 768px) {
				&:hover {
				  background-color: rgba(255, 227, 160, 0.59);
				}
			  }
  
			  &.select {
				background-color: #d3d3d3;
			  }
			}
  
			li.active, li.active:hover {
			  background-color: rgba(31, 42, 58, 0.73);
			  color: white;
			  //cursor: default;
			}
			.disable, .disable:hover, li.disable, li.disable:hover {
			  background-color: transparent;
			  color: #bbb;
			  cursor: default;
			}
		  }
		}
		// for dark theme pad
		&.dark {
		  color: #fff;
		  background-color: rgba(50, 50, 50, 0.96);
  
		  @media screen and (max-width: 767px) {
			&.range .rmp-pad {
			  border-top: 1px solid rgba(113, 113, 113, 0.41);
			  &:first-of-type {
				border-top: 0;
			  }
			}
		  }
		  @media screen and (min-width: 768px) {
			&.range .rmp-pad {
			  background-color: rgba(70, 70, 70, 0.9);
			}
		  }
  
		  .rmp-pad {
			.rmp-btn {
			  cursor: pointer;
			  @include unselectable();
  
			  @media screen and (min-width: 768px) {
				&:hover {
				  background-color: rgba(255, 210, 96, 0.33);
				}
			  }
  
			  &.select {
				background-color: #262828;
			  }
			}
  
			li.active, li.active:hover {
			  background-color: rgba(189, 211, 242, 0.7);
			  color: #303030;
			  //cursor: default;
			}
			.disable, .disable:hover, li.disable, li.disable:hover {
			  background-color: transparent;
			  color: #717171;
			  cursor: default;
			}
		  }
		}
  
	  } //.rmp-popup
	} //.rmp-container
  
  
  }
  