@import "../styles/Variables.scss";

.link-list-icons {
	display: flex;

	div {
		margin-right: 20px;

		&:last-of-type {
			margin-right: 0;
		}
	}
}