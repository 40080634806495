@import "../styles/Variables.scss";

.notification-bell-wrapper {
	position: relative;
	z-index: 100;

	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: 12px;
		height: 12px;
		background-color: $colour-accent-2;
		border-radius: 50%;
		transform: scale(0);
		transition: 200ms ease;
	}

	&.unread-notifications {
		.notification-bell {
			stroke: $colour-secondary;
		}

		&:after {
			transform: scale(1);
		}

		&.notifications-open {
			.notification-bell {
				transform: rotate(-20deg);
			}
	
			&:after {
				transform: scale(0.8);
			}
		}
	}

	&.notifications-open {
		.notifications-wrapper {
			top: 35px;
			visibility: visible;
			opacity: 1;
			transition: 300ms ease-in-out;
		}

		.notification-bell {
			stroke: $colour-secondary;
			transform: rotate(-20deg);
		}
	}
}

.notifications-wrapper {
	position: absolute;
	right: -8px;
	top: 25px;
	visibility: hidden;
	opacity: 0;
	transition: 300ms ease-in-out;
}

.notification-bell {
	stroke: $colour-inactive;
	cursor: pointer;
	transition: 300ms ease;

	&:hover {
		stroke: $colour-secondary;
	}
}