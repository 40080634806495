@import "../styles/Variables.scss";

.nav-link-item {
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: $fw-medium;
	color: $colour-text-primary !important;
	opacity: 0.3;
	text-decoration: none;
	width: 100%;
	display: block;
	position: relative;
	outline: none;

	&:before, &::after {
		content: "";
		width: 4px;
		border-radius: 10px;
		position: absolute;
		right: 0;
		height: 0;
		transition: 400ms ease;
		transform: scaleY(0.1);
		transform-origin: bottom;
		opacity: 0;
	}
	
	&:after {
		height: 90%;
		bottom: -3px;
		background-color: $colour-secondary;
	}
	
	&:before {
		height: 40%;
		top: -2px;
		background-color: $colour-accent-2;
	}
	
	&:hover {
		opacity: 1;
		
		svg {
			.blue-stroke {
				stroke: $colour-secondary;
			}

			.gold-stroke {
				stroke: $colour-accent-2;
			}
		}
	}

	svg {
		width: 20px;
		margin-right: 28px;
		
		.blue-stroke, .gold-stroke {
			transition: 200ms ease;
			stroke: $colour-text-primary;
		}
	}

	&.active-nav-link-item {
		font-weight: $fw-semi-bold;
		opacity: 1;

		&:before, &:after {
			transform: scaleY(1);
			opacity: 1;
		}
		
		svg {
			.blue-stroke {
				stroke: $colour-secondary;
			}

			.gold-stroke {
				stroke: $colour-accent-2;
			}
		}
	}

	// Remove margin from last item
	&:last-of-type {
		margin-bottom: 0;
	}
}