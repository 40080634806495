@import "../styles/Variables.scss";

.login-container {
	height: 100vh;
	padding: 25px;

	.portal-card {
		padding: 80px;	
	}
}
