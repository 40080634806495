@import "../styles/Variables.scss";

.profile-image {
	width: 38px;
	height: 38px;
	color: $colour-secondary;
	background: $colour-primary-light-blue;
	border-radius: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 500;
}