@import "../styles/Variables.scss";

.p-button {
	&.full-width {
		width: 100%;
	}

	&.MuiButton-root {
		min-width: 145px;
		background-color: $colour-secondary;
		font-family: $font-primary;
		border-radius: $border-radius-button;
		padding: 11px 25px;

		&.colour-primary {
			span {
				color: $colour-secondary;
			}
		}

		&.colour-white {
			span {
				color: #fff;
			}
		}

		&.bg-colour-accent {
			background-color: $colour-accent-2;
		}

		span {
			color: #fff;
			font-weight: $fw-regular;
			font-size: $fs-body;
			text-transform: none;
		}

		&:hover {
			background-color: rgba($colour-secondary, 0.9);
		}
	}
	
	&.MuiButton-outlined {
		background-color: transparent;
		border-width: 2px;
		border-color: $colour-secondary;

		&.bg-colour-accent {
			border-color: $colour-accent-2;
		}

		&.bg-colour-white {
			border-color: #fff;
		}

		&:hover {
			background-color: $colour-secondary !important;
			
			&.bg-colour-accent {
				background-color: $colour-accent-2;
			}
	
			&.bg-colour-white {
				background-color: #fff;
			}
		}
	}

	&.Mui-disabled {
		opacity: 0.4;
		background-color: $colour-inactive;
	}
}