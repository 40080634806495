@import "../styles/Variables.scss";

.search-field-wrapper {
	position: relative;

	.input-field-wrapper .MuiFilledInput-input {
		padding: 12.5px 15px !important;
	}	

	svg {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		stroke: $colour-secondary;
	}
}