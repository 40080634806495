@import "../styles/Variables.scss";

.portal-table {
	width: 100%;

	tr {
		border-top: 1px solid $colour-divider;

		&:last-of-type {
			border-bottom: 1px solid $colour-divider;
		}
	}

	th {
		font-weight: $fw-semi-bold;
	}

	td {
		font-size: $fs-body;
		padding: 15px 0;
	}

	.right-align {
		text-align: right;
	}
}

.portal-table-header {
	font-weight: $fw-medium;
	margin-bottom: 25px;
}