@import "../styles/Variables.scss";

.favourite-icon {
	stroke: $colour-inactive;
	cursor: pointer;

	&:hover {
		stroke: $colour-secondary;
	}

	&.icon-active {
		stroke: $colour-secondary;
		path {
			fill: $colour-secondary;
		}
	}
}