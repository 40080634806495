@import "../styles/Variables.scss";

.dashboard-card {
	overflow: auto;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
}