// Colours
$colour-primary-white: #fff;
$colour-primary-black: #2F3134;
$colour-primary-light-blue: #F2F5FC;
$colour-secondary: #254A8F;
$colour-accent-1: #FFDB8F;
$colour-accent-2: #DBB564;
$colour-text-primary: #2f3134;
$colour-divider: #E7EAF2;
$colour-error: #F5245C;
$colour-inactive: #CBD1D9;
$colour-item-hover: rgba(#F2F5FC, 0.5);

// Font
$font-primary: 'Poppins', sans-serif;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fs-body: 15px;
$fs-small: 13px;

// Layout / Misc
$page-content-border-radius: 35px;
$border-radius: 10px;
$border-radius-button: 10px;
$shadow: 0 0 4px rgba(#000, 0.09);
$card-border: 1px solid #F2F5FC;

// Breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// max widths
$max-small-size: 540px;
$max-medium-size: 720px;
$max-large-size: 900px;
$max-extra-large-size: 1280px;