@import "../styles/Variables.scss";

.profile-dropdown-toggle-items {
	display: flex;
	align-items: center;
	padding: 3px;
	border-radius: 14px;
}

.profile-name-wrapper {
	display: flex;
	align-items: center;
}

.profile-name {
	font-weight: $fw-medium;
	margin-left: 11px;
	font-size: 14px;
}

.profile-carat {
	border-radius: 50%;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $colour-primary-light-blue;
	margin-left: 5px;

	svg {
		stroke: $colour-secondary;
		stroke-width: 4px;
	}
}

.profile-menu {
	min-width: 150px;
}