@import "./Variables.scss";

h1,h2,h3,h4,h5,h6 {
	font-weight: 600;
	margin: 0;
	margin-bottom: 20px;
	line-height: 1.2;
}

h1 {
	font-size: 32px;
}

h2 {
	font-size: 27px;
}

h3 {
	font-size: 23px;
	margin-bottom: 15px;
}

h4 {
	font-size: 19px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 13px;
}

p {
	font-size: 15px;
	font-weight: 400;
	margin: 0;
	margin-bottom: 10px;
	line-height: 1.4;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

small {
	font-size: smaller;
}

.colour-white {
	color: #fff;
}

.colour-secondary {
	color: $colour-secondary;
}

.colour-accent-1 {
	color: $colour-accent-1;
}

.colour-accent-2 {
	color: $colour-accent-2;
}

.small-text {
	font-size: $fs-small;
}