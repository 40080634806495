@import "../styles/Variables.scss";

.alert-card-item {
	background-color: #fff;
	border-radius: $border-radius;
	padding: 14px;
	border: 2px solid $colour-primary-light-blue;
	width: 100%;
	font-size: $fs-body;
	margin-bottom: 10px;
	cursor: pointer;

	&.unread-item {
		background-color: $colour-primary-light-blue;
	}

	&.no-margin {
		margin-bottom: 0;
	}
	
	&:hover {
		background-color: $colour-item-hover;
	}
}

.alert-card-item-text {
	font-weight: $fw-semi-bold;
	width: 100%;
}

.alert-card-item-sub-text {
	font-weight: $fw-regular;
	width: 100%;
	margin-bottom: 7px;
}

.alert-card-item-date {
	font-weight: $fw-regular;
	color: $colour-secondary;
	width: 100%;
}