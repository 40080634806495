@import "../styles/Variables.scss";

.notification-item-wrapper {
	background-color: #fff;
	padding: 11px 35px 11px 19px;
	cursor: pointer;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
		width: 10px;
		height: 10px;
		background-color: $colour-accent-2;
		border-radius: 50%;
		display: none;
	}

	&:hover {
		background-color: rgba($colour-primary-light-blue, 0.6);
	}
	
	&.unread-notification {
		background-color: $colour-primary-light-blue;

		&:after {
			display: block;
		}

		.notification-text {
			font-weight: $fw-semi-bold;
			color: $colour-secondary;
		}
	}
}

.notification-text {
	font-size: $fs-body;
	color: $colour-text-primary;
	line-height: 1.3;
}

.notification-date {
	font-size: 11px;
	color: $colour-secondary;
	font-weight: $fw-medium;
	opacity: 0.5;
}