@import "../styles/Variables.scss";

.page-layout-master-wrapper {
	width: 100vw;
	height: 100vh;
	overflow: auto;
	display: grid;
	grid-template-columns: 333px calc(100vw - 333px);
}

.page-content-wrapper {
	background-color: $colour-primary-white;
	border-top-left-radius: $page-content-border-radius;
	border-bottom-left-radius: $page-content-border-radius;
}

.page-content-inner {
	padding: 30px 100px 30px 48px;
	height: calc(100vh - 130px);
	overflow: auto;
}