@import "../styles/Variables.scss";

.notification-list-wrapper {
	width: 346px;
	padding: 20px 0 50px 0;
	background-color: #fff;
	border-radius: $border-radius;
	border: $card-border;
	box-shadow: $shadow;
	max-height: 80vh;
	overflow: auto;
}

.notification-list-header {
	padding-left: 19px;
	padding-bottom: 20px;
	background-color: #fff;

	h4 {
		margin-bottom: 0;
	}
}

.MuiTypography-h6 {
	font-family: $font-primary !important;
}