:root {
	--body-bg: #FDFDFD;
	--thead-bg: #F7F9FB;
	--thead-color: hsl(210, 5%, 40%);
	--bd-color: #ebebeb;
	--time-color: hsl(210, 5%, 70%);
	
	--time-width: 75px;
	--sticky-height: 40px;
  }
  
  .portal-schedule {

	.calendar-section {
		background: var(--body-bg);
		overflow-x: hidden;
	}
	
	.wrapper {
		padding: 15px;
		margin: 10em auto 30em;
		max-width: 1080px;
	}
		
	.sticky-header {
		height: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--body-bg);
		z-index: 7;
		position: sticky;
		top: 0;
		font-weight: 700;
		overflow: hidden;
	}
	
	.sticky-header span {
		opacity: 0;
		transform: translateY(-100%);
		transition: .4s;
	}
	
	.reveal .sticky-header span {
		opacity: 1;
		transform: none;
	}
	
	.table {
		position: relative;
		border: solid var(--bd-color);
		border-width: 0 1px 0 0;
		overscroll-behavior: contain;
	}
	
	.headers {
		top: 0px;
		position: -webkit-sticky;
		position: sticky;
		display: flex;
		justify-content: flex-end;
		z-index: 1;
		box-shadow: 0 10px 50px rgba(0, 0, 0, 0.04);
	}
	
	.tracks,
	.scroller {
		display: flex;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
	}
	
	.scroller {
		overflow-x: hidden;
		flex: 1;
	}
	
	.tracks {
		overflow: auto;
	}
	
	.tracks::-webkit-scrollbar,
	.scroller::-webkit-scrollbar {
		display: none;
	}
	
	.track {
		flex: 1 1 0px;
	}
	
	.track + .track {
		margin-left: -1px;
	}
	
	.time {
		flex: 0 0 var(--time-width);
		position: -webkit-sticky;
		position: sticky;
		left: 0;
	}
	
	.headers .time {
		z-index: 5;
	}
	
	.tracks .time {
		box-shadow: 20px 0 50px rgba(0, 0, 0, 0.05);
	}
	
	time {
		font-weight: 600;
		font-size: 12px;
		letter-spacing: .03em;
	}
	
	time {
		color: hsl(210, 5%, 70%);
		text-align: right;
	}
	
	.time .heading {
		justify-content: flex-end;
		padding-right: 1em;
		font-weight: 500;
		background: #F9F9F9;
	}
	
	.heading {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		border: solid var(--bd-color);
		border-width: 1px;
		color: hsla(210, 5%, 40%, 1);
		z-index: 1;
		background: var(--thead-bg);
		font-weight: 700;
	}
	
	.entry {
		border: 1px solid #ebebeb;
		border-top: 0;
		background: var(--body-bg);
		height: 5em;
		padding: 1em;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	
	.entry:not(:last-of-type) {
		border-bottom-style: dashed;
	}
	
	.track:last-of-type > div {
		border-right: 0;
	}
	
	.time .entry,
	.time .heading {
		position: relative;
		border-color: transparent var(--bd-color) var(--bd-color) transparent;
		border-color: transparent var(--bd-color) transparent transparent;
	}
	
	.time .entry:after,
	.time .heading:after {
		content: '';
		position: absolute;
		bottom: -1px;
		right: -1px;
		width: 50%;
		height: 1px;
		z-index: 3;
		background: linear-gradient(to left, var(--bd-color), var(--body-bg));
	}
	
	.double {
		height: 18em;
	}
	.treble {
		height: 27em;
	}
	
	.details {
		border-radius: 4px;
		background: #fff;
		box-shadow: 0 15px 30px -10px rgba(0,0,0,0.50);
		box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
		border: 1px solid #EBECEE;
		padding: 10px;
		border-left: 5px solid var(--theme-bg);
		height: 100%;
		flex-shrink: 1;
	}
	
	.bodyPreview {
		
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	h3 {
		font-size: 12px;
		background: var(--theme-bg);
		padding: 2px 5px;
		font-weight: 600;
		line-height: 1.4;
		border-radius: 2px;
		color: var(--theme-color);
	/*	max-width: max-content;*/
	}
	
	.yellow {
		--theme-color: hsl(40, 50%, 30%);
		--theme-bg: #ffd800;
	}
	
	.turq {
		--theme-color: hsl(180, 50%, 30%);
		--theme-bg: #00facd;
	}
	
	.blue {
		--theme-color: hsl(0, 0%, 100%);
		--theme-bg: #254A8F;
	}
	
	.purple {
		--theme-color: hsl(235, 75%, 90%);
		--theme-bg: #473BBE;
	}
	
	.green {
		--theme-color: #2d4710;
		--theme-bg: #86F250;
	}
	
	.gray {
		--theme-color: var(--thead-bg);
		--theme-bg: #BBBCBC;
	}
	
	p {
		font-size: 12px;
		color: #333;
		font-weight: 500;
		margin: 0;
	}
	
	p + p {
		color: #858585;
		margin-top: 5px;
		font-weight: 400;
	}
	
	.navigate {
		cursor: pointer;
	}

	.button-left, .button-right {
		margin-top: 10px
	}

	.buttons {
		display: flex;
		justify-content: space-between;
		position: absolute;
		z-index: 6;
		height: 100%;
		padding: 1px 0;
		width: calc(100% - var(--time-width));
	}
	
	button {
		border-radius: 0;
		border: 0;
		padding: 5px;
		font: inherit;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none !important;
		cursor: pointer;
		background: var(--thead-bg);
	}
	
	button > svg {
		line-height: 0;
		width: 30px;
		height: 30px;
		fill: var(--thead-color);
		pointer-events: none;
	}
	
	.btn-left {
		transform: scaleX(-1);
	}
	
	
	#top-of-site-pixel-anchor {
		position: absolute;
		width: 1px;
		height: 1px;
		top: 15em;
		left: 0;
	}
	
	@media (max-width: 767px) {
		.track:not(.time) {
		flex: 1 0 calc(50% + 7px);
		}
	}
}