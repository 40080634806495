@import "../styles/Variables.scss";

.dropdown-card {
	background-color: #fff;
	border-radius: $border-radius;
	box-shadow: $shadow;
	padding-top: 10px;
	padding-bottom: 20px;
	border: $card-border;
}
