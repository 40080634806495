@import "../styles/Variables.scss";

.dropdown-item {
	background-color: #fff;
	color: $colour-secondary;
	font-size: $fs-body;
	padding: 11px 15px;
	cursor: pointer;
	transition: 200ms ease;

	&.active-item {
		font-weight: $fw-medium;
		background-color: $colour-primary-light-blue;
	}

	&:hover {
		color: $colour-secondary;
		background-color: $colour-primary-light-blue;
	}

	&:active {
		background-color: rgba($colour-secondary, 0.3);
	}
}
