@import "../styles/Variables.scss";

.dropdown-container {
	position: relative;

	&.dropdown-open {
		.dropdown-content {
			top: 105%;
			visibility: visible;
			opacity: 1;
			transition: 300ms ease-in-out;
		}
	}

	&.right-aligned {
		.dropdown-content {
			right: 0;
		}
	}
}

.dropdown-toggler {
	cursor: pointer;
}

.dropdown-content {
	position: absolute;
	top: 90%;
	visibility: hidden;
	opacity: 0;
	transition: 300ms ease-in-out;
}