
@import "../styles/Variables.scss";

.MuiTooltip-popper {
	.MuiTooltip-tooltip {
		background-color: #fff;
		border: none;
		font-family: $font-primary;
		color: $colour-text-primary;
		font-size: 12px;
		box-shadow: $shadow;
		border: $card-border;
	}
	
	.MuiTooltip-arrow {
		&:before {
			box-shadow: $shadow;
			background-color: #fff;
			border: $card-border;
		}
	}
}